// Constants.js

const DATASLAYER_URL =
  import.meta.env.VITE_DATASLAYER_URL || 'https://app.dataslayer.ai'
const API_URL =
  import.meta.env.VITE_API_URL || 'https://morpheus-api.dataslayer.ai'

const prod = {
  API_URL: API_URL,
  DATASLAYER_URL: DATASLAYER_URL,
}

const dev = {
  API_URL: `https://test-mmm-api.dataslayer.ai`,
  DATASLAYER_URL: 'https://test.dataslayer.ai',
}

export const config = dev
export const HELP_URL = 'https://support.dataslayer.ai/morpheus' // TODO Discuss if we're gonna keep the help
