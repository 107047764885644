import { Button, Col, Row, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import proCrown from '../../assets/images/pro_crown.svg'
import { Link } from 'react-router-dom'
import pdfIcon from '../../assets/images/pdf.png'

export default function UpgradeToPro({ showSampleReport = false }) {
  const { t } = useTranslation()

  return (
    <Row
      className="display-blur absolute  bg-[#23384F] bg-opacity-100 opacity-100 z-10 top-[10%] rounded-[20px] py-[50px] !px-[60px]"
      style={{
        left: 'max(5%, calc(50% - 495px)) ',
        maxWidth: 'min(990px, 90%)',
      }}
    >
      <Col className="flex flex-nowrap text-[26px] justify-center" xs={12}>
        <img src={proCrown} className="pe-2" />
        {t('Unlock Advanced Data Sources and Marketing Mix Modeling!')}
      </Col>
      <Col className="text-center !mt-8 text-[#ffffff60] text-[18px]" xs={12}>
        {t('Connect')} <strong>{t('more data sources')}</strong>{' '}
        {t(
          'like Facebook Ads, Klaviyo, Shopify, or TV campaigns, and automate your data. With',
        )}{' '}
        <strong>{t('Marketing Mix Modeling')}</strong>
        {t(
          '(MMM), see how all your channels work together to drive results. Upgrade now for deeper insights!',
        )}
      </Col>
      <Col xs={12} className="!mt-12 flex justify-center">
        <Button
          as={Link}
          to={
            'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm?uuid=0eb8c934-f5af-4869-8bbc-9e3ec8cc4b64'
          }
          target="_blank"
          rel="noopener noreferrer"
          className="!rounded-[30px] !text-[20px] !px-[56px] !bg-transparent !font-bold !shadow-none !border-4  !border-[#98a2e5] !text-[#98a2e5] !flex items-center"
        >
          <span>{t('CONTACT US')}</span>
        </Button>
        {showSampleReport && (
          <Button
            as={Link}
            to={'/Optimization_Sample.pdf'}
            target="_blank"
            rel="noopener noreferrer"
            className="!rounded-[30px] !text-[20px] !px-[26px] !bg-morpheusMain !font-bold !shadow-none !border-none ms-2 !flex items-center "
          >
            <div className="inline-flex flex-nowrap items-center">
              <Image src={pdfIcon} className="max-h-[25px]" />
              {t('GET A SAMPLE DATA REPORT')}
            </div>
          </Button>
        )}
      </Col>
    </Row>
  )
}
